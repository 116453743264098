import React from 'react';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Box from '@mui/material/Box';

import { CommunityScrollContainer } from 'src/components/home/CommunityScrollContainer';
import HomeSearch from 'src/components/home/HomeSearch';
import { JoinWoopen } from 'src/components/home/JoinWoopen';
import { RedirectCTAs } from 'src/components/home/RedirectCTAs';
import Footer from 'src/components/other/Footer';
import Header from 'src/components/other/Header';
import { IndividualPresentation } from 'src/components/home/IndividualPresentation';
import { ApplicationPresentation } from 'src/components/home/ApplicationPresentation';
import { EstimationPresentation } from 'src/components/home/EstimationPresentation';

const Home: PageComponent = () => (
    <>
        <Header />
        <HomeSearch />
        <RedirectCTAs />
        <EstimationPresentation />
        <IndividualPresentation />
        <CommunityScrollContainer />
        <ApplicationPresentation />
        <JoinWoopen />
        <Box mt="auto">
            <Footer />
        </Box>
    </>
);

export const getServerSideProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale, ['common', 'news', 'home-page', 'community', 'company', 'profile'])),
        SEOProps: {
            pageTitleTranslation: { key: 'common:seo.home.title' },
            metaDescriptionTranslation: { key: 'common:seo.home.description' },
        },
    },
});

export default Home;
