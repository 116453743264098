import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import dynamic from 'next/dynamic';

import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system';

import { useTranslation } from 'next-i18next';

import Typography from 'src/components/ui/Typography';
import { LoginButton } from 'src/components/auth/SocialSignInButtons';
import Button from 'src/components/ui/Button';
import StoreButton from 'src/components/ui/StoreButton';
import Image from 'src/components/ui/Image';

import { breakpoints } from 'src/constants/breakpoints';
import { colours } from 'src/constants/colours';

import useAuthActions from 'src/hooks/useAuthActions';
import { routePathSelectProfileType } from 'src/constants/router';
import useUser from 'src/hooks/useUser';
import { styledComponent } from 'src/utils/styled';

const WoopenGoogleQRCode = dynamic(() => import('src/assets/logos/qrcode-woopen-google.svg'), {
    ssr: false,
});

// The default 360px value is too small for this component so I use 560 to have a good responsive
const customMobileBreakpoint = 560;

const Container = styledComponent(Box)`
    display: flex;
    background: linear-gradient(to right, ${colours.grey.G500}, ${colours.white.main});
    background-size: cover;
    background-position-x: left;
    background-position-y: 115%;
    height: 28rem;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    position: relative;

    // To select only the span parent of the image
    > img:first-of-type {
        position: absolute !important;
        left: 23rem;
        bottom: 0;
    }

    @media (min-width: ${breakpoints.desktopExtraLarge}px) {
        padding-right: 10rem;
        padding-left: 10rem;

        > img:first-of-type {
            left: 30rem;
        }
    }

    @media (max-width: 1100px) {
        padding: 3rem;
        background-position-x: 20%;

        > img:first-of-type {
            left: 15rem;
        }
    }

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        justify-content: flex-end;

        > img:first-of-type {
            left: 10rem;
        }
    }

    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        background-position-x: 35%;
        height: 25rem;

        > img:first-of-type {
            left: 3rem;
            height: 24rem !important;
            width: 20.5rem !important;
        }
    }

    @media (max-width: ${customMobileBreakpoint - 1}px) {
        height: 22rem;

        > img:first-of-type {
            left: 1rem;
            height: 20.5rem !important;
            width: 17.5rem !important;
        }
    }

    @media (max-width: 440px) {
        padding: 0.5rem;

        > img:first-of-type {
            left: -1rem;
        }
    }
`;

const MobileAppImage = styledComponent(Image)`
    background-color: transparent !important;
`;

const MobileAppBox = styledComponent(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15.625rem;

    h3 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        align-items: center;
        h3 {
            text-align: center;
        }
    }

    @media (max-width: ${customMobileBreakpoint - 1}px) {
        width: 10.625rem;
        h3 {
            font-size: 1.2rem;
        }
    }
`;

const StoreContainer = styledComponent(Box)`
    margin-bottom: 0.5rem;

    @media (max-width: ${customMobileBreakpoint - 1}px) {
        svg {
            width: 8.75rem;
            height: 2.75rem;
        }
    }
`;

const StyledSignupContainer = styledComponent(Box)`
    display: flex;
    flex-direction: column;
    background-color: ${colours.white.main};
    height: auto;
    overflow: hidden;

    @media (min-width: ${breakpoints.tabletLandscape}px) {
        border-radius: 0.5rem;
        width: 25rem;
    }
`;

const StyledBox = styledComponent(Box)`
    margin: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        margin: 3rem;
        margin-bottom: 1rem;
        text-align: center;
        align-items: center;

        h3,
        h5 {
            width: 22rem;
        }
    }
`;

const LoginButtonsContainer = styledComponent(Box)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (min-width: ${customMobileBreakpoint}px) and (max-width: ${breakpoints.tabletLandscape - 1}px) {
        flex-direction: row;
    }

    @media (min-width: ${customMobileBreakpoint}px) and (max-width: ${breakpoints.tabletPortrait - 1}px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media (max-width: ${customMobileBreakpoint - 1}px) {
        width: 100%;
    }
`;

const StyledLoginButton = styledComponent(LoginButton)`
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    min-width: 13.5rem;

    @media (min-width: ${customMobileBreakpoint}px) and (max-width: ${breakpoints.tabletLandscape - 1}px) {
        flex: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
        margin: 0.5rem;
    }
`;

const createAccountButton: SxProps = {
    padding: '0.75rem',
    borderRadius: 0,
    [`@media screen and (max-width: ${breakpoints.tabletLandscape - 1}px)`]: {
        borderRadius: '0.25rem',
    },
    [`@media screen and (max-width: ${breakpoints.tabletLandscape - 1}px)`]: {
        margin: '0 2rem',
        marginBottom: '1rem',
    },
};

export const JoinWoopen: React.FC = () => {
    const { t } = useTranslation(['home-page']);
    const currentUser = useUser();
    const { width } = useWindowSize();

    const { isMobile, isDesktop } = useMemo(
        () => ({
            isMobile: width < customMobileBreakpoint,
            isDesktop: width >= breakpoints.tabletLandscape,
        }),
        [width],
    );

    return (
        <>
            <Container>
                <MobileAppImage
                    src={`${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/woopen-mobile-app.png`}
                    alt="join-woopen-app"
                    width={365}
                    height={425}
                />
                <MobileAppBox>
                    <Typography mb="1rem" variant="h2" data-target-id="mobile-app-title" serif>
                        {t('home-page:joinUsMobileApp')}
                    </Typography>
                    <StoreContainer>
                        <StoreButton store="google" width={175} height={55} />
                    </StoreContainer>
                    <StoreContainer>
                        <StoreButton store="apple" width={175} height={55} />
                    </StoreContainer>
                    {!isMobile && <WoopenGoogleQRCode width={90} height={90} data-target-id="qr-code-woopen" />}
                </MobileAppBox>
                {!currentUser && isDesktop && <SignupSection />}
            </Container>
            {!currentUser && !isDesktop && <SignupSection />}
        </>
    );
};

const SignupSection: React.FC = () => {
    const { t } = useTranslation(['home-page']);
    const { signUpSocial } = useAuthActions();

    return (
        <StyledSignupContainer data-target-id="signup-section">
            <StyledBox>
                <Typography variant="h3" style={{ fontSize: '1.3rem' }} data-target-id="signup-section-title">
                    {t('home-page:joinCommunity')}
                </Typography>
                <Typography variant="h5" color="secondary" data-target-id="signup-section-subtitle">
                    {t('home-page:itsFree')}
                </Typography>
                <Typography
                    variant="h5"
                    color="textPrimary"
                    style={{ fontWeight: 400, margin: '1rem 0' }}
                    data-target-id="signup-section-method"
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('home-page:chooseSignupMethod'),
                        }}
                    />
                </Typography>
                <LoginButtonsContainer>
                    <StyledLoginButton provider="facebook" onClick={() => signUpSocial({ provider: 'facebook' })} />
                    <StyledLoginButton provider="google" onClick={() => signUpSocial({ provider: 'google' })} />
                    <StyledLoginButton provider="apple" onClick={() => signUpSocial({ provider: 'apple' })} />
                </LoginButtonsContainer>
            </StyledBox>
            <Button
                href={routePathSelectProfileType}
                data-target-id="signup-section-create-account"
                sx={createAccountButton}
            >
                {t('home-page:createNewAccount')}
            </Button>
        </StyledSignupContainer>
    );
};
