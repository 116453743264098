import React from 'react';
import { useTranslation } from 'next-i18next';

import Box from '@mui/material/Box';
import type { SxProps } from '@mui/material/styles';

import { styledComponent, styledTag } from 'src/utils/styled';
import { breakpoints } from 'src/constants/breakpoints';
import { colours } from 'src/constants/colours';
import Typography from '../ui/Typography';
import Icon from '../ui/Icon';
import Button from '../ui/Button';
import useAuthenticatedRedirect from 'src/hooks/useAuthenticatedRedirect';
import {
    routePathAd,
    routePathCommunity,
    routePathCreateAdStep1,
    routePathNews,
    routePathEstimation,
} from 'src/constants/router';
import useBreakpoints from 'src/hooks/useBreakpoints';

const Container = styledTag('div')`
    display: grid;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    grid-template-columns: repeat(5, 17rem);
    grid-template-areas: 'StyledBoxCta1 StyledBoxCta2 StyledBoxCta3 StyledBoxCta4 StyledBoxCta5';
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding-bottom: 2rem;
    .StyledBoxCta1 {
        grid-area: StyledBoxCta1;
    }
    .StyledBoxCta2 {
        grid-area: StyledBoxCta2;
    }
    .StyledBoxCta3 {
        grid-area: StyledBoxCta3;
    }
    .StyledBoxCta4 {
        grid-area: StyledBoxCta4;
    }
    .StyledBoxCta5 {
        grid-area: StyledBoxCta5;
    }

    @media (max-width: ${breakpoints.desktopLarge - 1}px) {
        grid-template-columns: repeat(3, 0.18fr);
        grid-template-rows: 15rem 15rem;
        column-gap: 3rem;
        grid-template-areas:
            'StyledBoxCta1 StyledBoxCta2 StyledBoxCta3'
            'StyledBoxCta4 StyledBoxCta4 StyledBoxCta5';
        > :last-child {
            grid-column-start: 2;
        }
    }

    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        grid-template-columns: repeat(2, 0.18fr);
        grid-template-rows: 12rem 12rem 12rem;
        margin-left: 5rem;
        column-gap: 0rem;
        grid-template-areas:
            'StyledBoxCta1 StyledBoxCta2'
            'StyledBoxCta3 StyledBoxCta4'
            'StyledBoxCta5 StyledBoxCta5';
        > :last-child {
            grid-column-start: 1;
        }
    }
`;

const StyledBoxCta = styledComponent(Box)`
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        margin-right: 2.3rem;
    }
`;

const StyledTitle = styledComponent(Typography)`
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 0.9rem;
    }
`;

const StyledDescription = styledComponent(Typography)`
    font-size: 1rem;
    font-weight: 400;
    color: ${colours.grey.G500};
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 0.75rem;
    }
`;

const StyledBox = styledComponent(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 14rem;
    width: 14rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        height: 10rem;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        width: 9rem;
    }
    > :last-child {
        margin-top: auto;
    }
`;

const redirectButtonStyle: SxProps = {
    width: '13rem',
    fontWeight: 500,
    backgroundColor: colours.secondary.main,
    [`@media screen and (max-width: ${breakpoints.tabletPortrait - 1}px)`]: {
        width: '10rem',
        fontSize: '0.7rem',
    },
};

export const RedirectCTAs: React.FC = () => {
    const authenticatedRedirect = useAuthenticatedRedirect();

    return (
        <Container>
            <StyledBoxCta className="StyledBoxCta1">
                <RedirectCtaCard
                    icon="CtaEstimate"
                    title="news:estimateProperty"
                    description="home-page:estimatePropertyDescription"
                    action={{
                        type: 'link',
                        title: 'home-page:estimatePropertyButton',
                        href: routePathEstimation,
                    }}
                />
            </StyledBoxCta>
            <StyledBoxCta className="StyledBoxCta2">
                <RedirectCtaCard
                    icon="CtaPlaceAnAd"
                    title="news:placeAd"
                    description="home-page:sellOrRentHousing"
                    action={{
                        type: 'button',
                        title: 'home-page:iPlaceAnAd',
                        onClick: () => authenticatedRedirect(routePathCreateAdStep1),
                    }}
                />
            </StyledBoxCta>
            <StyledBoxCta className="StyledBoxCta3">
                <RedirectCtaCard
                    icon="CtaSearch"
                    title="home-page:searchProperty"
                    description="home-page:accessNetwork"
                    action={{
                        type: 'link',
                        title: 'home-page:iSearchProperty',
                        href: routePathAd,
                    }}
                />
            </StyledBoxCta>
            <StyledBoxCta className="StyledBoxCta4">
                <RedirectCtaCard
                    icon="CtaFindAPro"
                    title="home-page:findPros"
                    description="home-page:findProDescription"
                    action={{
                        type: 'link',
                        title: 'home-page:iSearchPros',
                        href: routePathCommunity,
                    }}
                />
            </StyledBoxCta>
            <StyledBoxCta className="StyledBoxCta5">
                <RedirectCtaCard
                    icon="CtaSearch"
                    title="home-page:followNews"
                    description="home-page:followNewsDescription"
                    action={{
                        type: 'link',
                        title: 'home-page:iCheckTheNews',
                        href: routePathNews,
                    }}
                />
            </StyledBoxCta>
        </Container>
    );
};

interface RedirectCtaCardProps {
    icon: IconProps['name'];
    title: string;
    description: string;
    action: { title: string } & ({ type: 'link'; href: string } | { type: 'button'; onClick: () => void });
}
const RedirectCtaCard: React.FC<RedirectCtaCardProps> = ({ icon, title, description, action }) => {
    const { t } = useTranslation(['company', 'home-page']);
    const { isMobile } = useBreakpoints();

    return (
        <StyledBox>
            <Box data-target-id="icon">
                <Icon size={isMobile ? '2.5rem' : '4rem'} name={icon} />
            </Box>
            <StyledTitle variant="h3" data-target-id="title">
                {t(title)}
            </StyledTitle>
            <StyledDescription data-target-id="description" align="center">
                {t(description)}
            </StyledDescription>

            {action.type === 'link' ? (
                <Button sx={redirectButtonStyle} href={action.href}>
                    {t(action.title)}
                </Button>
            ) : (
                <Button sx={redirectButtonStyle} onClick={action.onClick}>
                    {t(action.title)}
                </Button>
            )}
        </StyledBox>
    );
};
