import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';

import { Box, Card, CardActionArea, CardMedia } from '@mui/material';

import { useTranslation } from 'next-i18next';

import { useActivityCategoriesQuery } from 'src/hooks/__generated__/queries';
import Typography from 'src/components/ui/Typography';
import { breakpoints } from 'src/constants/breakpoints';
import HorizontalScroller, { SnapItem } from 'src/components/ui/HorizontalScroller';
import { colours } from 'src/constants/colours';
import { cardHeightInRemForCommunityCard } from 'src/constants/misc';
import { styledComponent } from 'src/utils/styled';
import { routePathCommunity, routePathCommunityCategory } from 'src/constants/router';
import Button from '../ui/Button';
import type { ActivityCategoriesQuery } from 'src/types/__generated__/graphql';
import Icon from '../ui/Icon';

const StyledCard = styledComponent(Card)`
    position: relative;
    &.MuiCard-root {
        box-shadow: 0 0.25rem 0.625rem ${colours.greyOpacity.O10};
        width: 14.125rem;
        height: 19.75rem;
    }
`;

const StyledSingleCommunityBox = styledComponent(Box)`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 1rem;
`;

const StyledTypography = styledComponent(Typography)`
    color: ${colours.white.main};
    font-size: 1.125rem;
`;

const StyledWholeCommunityBox = styledComponent(Box)`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 316px;
    padding: 1rem;
    background: ${colours.secondary.main};
`;

const StyledIconBox = styledComponent(Box)`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
`;

const StyledBox = styledComponent(Box)`
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    @media (max-width: ${breakpoints.tabletPortrait}px) {
        margin-right: 2rem;
        flex-direction: column;
    }
`;

const SearchProButtonContainer = styledComponent(Box)`
    flex: 1;
    display: flex;
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;

    @media (max-width: ${breakpoints.tabletPortrait}px) {
        padding-top: 1.5rem;
        padding-left: 0;
        padding-right: 0;
        justify-content: start;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        justify-content: center;
    }
`;

const StyledContainer = styledComponent(Box)`
    background-color: ${colours.primary.O08};
    padding-top: 2rem;
`;

const TitleTypography = styledComponent(Typography)`
    font-size: 2rem;
    font-weight: bold;
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        font-size: 1.5rem;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 1.25rem;
    }
`;

const cardWidthInRem = 14.125;
export const CommunityScrollContainer: React.FC = () => {
    const { t } = useTranslation(['home-page']);
    const { width } = useWindowSize();

    const { data: activityCategories } = useActivityCategoriesQuery();
    const categories = activityCategories?.activityCategories
        ? activityCategories.activityCategories.filter((category) => category.parentActivityCategoryId === null)
        : [];

    const categoriesWithCommunityCard = [
        ...categories,
        {
            id: '71d2a2ea-e561-4629-b986-be29bc4a8b65',
            labelKey: 'community_card',
        },
    ];

    const { isDesktop2310, isLessThan881 } = useMemo(
        () => ({
            isDesktop2310: width > breakpoints.desktop2310,
            isLessThan881: width < breakpoints.breakPoint881,
        }),
        [width],
    );

    const leftSpaceInRem = isLessThan881 ? 2 : 5;

    return (
        <StyledContainer>
            <Box ml={`${leftSpaceInRem}rem`} mb="2rem">
                <TitleTypography variant="h2" mb="1rem">
                    {t('home-page:proCommunityTitle')}
                </TitleTypography>
                <StyledBox>
                    <Box display="flex" flexDirection="column">
                        <Typography>{t('home-page:proCommunityText')}</Typography>
                        <Typography mt="1rem" weight="bold">
                            {t('home-page:proCommunityTagline')}
                        </Typography>
                    </Box>
                    <SearchProButtonContainer>
                        <Button sx={{ width: 'max-content', height: 'fit-content' }} href={routePathCommunity}>
                            {t('home-page:iSearchAPro')}
                        </Button>
                    </SearchProButtonContainer>
                </StyledBox>
            </Box>

            {!isDesktop2310 ? (
                <Box paddingBottom="1rem">
                    <HorizontalScroller
                        actualCardsLength={categoriesWithCommunityCard.length}
                        totalCardsLength={categoriesWithCommunityCard.length}
                        cardWidthInPx={cardWidthInRem * 16}
                        cardHeightInRem={cardHeightInRemForCommunityCard}
                        paddingLeftInPx={leftSpaceInRem * 16}
                        paddingRightInPx={50}
                        isOnHomePage
                    >
                        {categoriesWithCommunityCard.map((activityCategory, index) => (
                            <SnapItem
                                key={activityCategory.labelKey}
                                paddingLeft={index === 0 ? `${leftSpaceInRem}rem` : undefined}
                            >
                                <CommunityCard key={activityCategory.labelKey} activityCategory={activityCategory} />
                            </SnapItem>
                        ))}
                    </HorizontalScroller>
                </Box>
            ) : (
                <Box display="flex" flexDirection="row" justifyContent="center" mb="1rem">
                    {categoriesWithCommunityCard.map((activityCategory) => (
                        <SnapItem key={activityCategory.labelKey}>
                            <CommunityCard key={activityCategory.labelKey} activityCategory={activityCategory} />
                        </SnapItem>
                    ))}
                </Box>
            )}
        </StyledContainer>
    );
};

type CommunityCardProps = {
    activityCategory: ArrayElement<ActivityCategoriesQuery['activityCategories']>;
};

const CommunityCard: React.FC<CommunityCardProps> = ({ activityCategory }) => {
    const isWholeCommunityCard = activityCategory.labelKey === 'community_card';

    return (
        <StyledCard elevation={0}>
            {isWholeCommunityCard ? (
                <WholeCommunityCard activityCategory={activityCategory} />
            ) : (
                <SingleCommunityCard activityCategory={activityCategory} />
            )}
        </StyledCard>
    );
};

const SingleCommunityCard: React.FC<CommunityCardProps> = ({ activityCategory }) => {
    const { t } = useTranslation(['home-page']);

    const image = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/${activityCategory.labelKey}.webp`;
    const href = `${routePathCommunityCategory}/${activityCategory.id}`;
    const label = t(`home-page:homePageCategories.${activityCategory.labelKey}`);

    return (
        <CardActionArea href={href}>
            <CardMedia component="img" image={image} alt={label} width={226} height={316} loading="lazy" />

            <StyledSingleCommunityBox>
                <Box width="10rem">
                    <StyledTypography>{label}</StyledTypography>
                </Box>
                <Chevron />
            </StyledSingleCommunityBox>
        </CardActionArea>
    );
};

const WholeCommunityCard: React.FC<CommunityCardProps> = ({ activityCategory }) => {
    const { t } = useTranslation(['home-page']);

    const href = routePathCommunity;
    const label = t(`home-page:homePageCategories.${activityCategory.labelKey}`);

    return (
        <CardActionArea href={href}>
            <StyledWholeCommunityBox>
                <Box>&nbsp;</Box>
                <Box display="flex" flexDirection="row" justifyContent="center">
                    <Icon name="AllCommunity" size="2.5rem" color="white" />
                </Box>
                <Box width="10rem">
                    <StyledTypography>{label}</StyledTypography>
                </Box>
                <Chevron />
            </StyledWholeCommunityBox>
        </CardActionArea>
    );
};

const Chevron: React.FC = () => (
    <StyledIconBox>
        <Icon name="ChevronRightCircle" size="1.7rem" />
    </StyledIconBox>
);

export default CommunityCard;
