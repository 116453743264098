import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useWindowSize } from 'react-use';

import Box from '@mui/material/Box';

import TextPresentation from './TextPresentation';
import { breakpoints } from 'src/constants/breakpoints';
import { styledComponent } from 'src/utils/styled';
import Typography from '../ui/Typography';

const StyledBoxWidth = styledComponent(Box)<{ $isOnWoopenPresentation?: boolean }>`
    width: 55rem;
    @media (max-width: ${breakpoints.breakPoint1500 - 1}px) {
        width: 40rem;
    }
    @media (max-width: ${breakpoints.breakPoint1230 - 1}px) {
        width: 32.5rem;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        width: 25rem;
    }
    @media (max-width: ${breakpoints.breakPoint881 - 1}px) {
        text-align: center;
        width: fit-content;
    }
`;

const StyledTypographyTitle = styledComponent(Typography)`
    font-size: 1.5rem;
    font-weight: bold;
    @media (max-width: ${breakpoints.desktopLarge - 1}px) {
        font-size: 1.5rem;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        font-size: 1.25rem;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 1.125rem;
    }
`;

const StyledTypographyText = styledComponent(Typography)`
    font-size: 1rem;
    text-align: left;
    @media (max-width: ${breakpoints.desktopLarge - 1}px) {
        font-size: 1rem;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        font-size: 0.875rem;
    }
`;

export const ApplicationPresentation: React.FC = () => {
    const { t } = useTranslation(['home-page']);
    const { width } = useWindowSize();

    const { isBreakPoint881, isBreakPoint1100 } = useMemo(
        () => ({
            isBreakPoint881: width < breakpoints.breakPoint881,
            isBreakPoint1100: width < breakpoints.breakPoint1100,
        }),
        [width],
    );

    const backgroundImage = isBreakPoint1100
        ? `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/home-presentation-321.webp`
        : `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/home-presentation-479.webp`;

    return (
        <TextPresentation
            content={
                <StyledBoxWidth
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    ml={isBreakPoint881 ? '2rem' : '5.5rem'}
                >
                    <Box display="flex" flexDirection="column" mr="2rem" pt="2rem">
                        <StyledTypographyTitle variant="h2">
                            {t('home-page:appPresentationTitle')}
                        </StyledTypographyTitle>
                        <StyledTypographyText
                            mt="1rem"
                            dangerouslySetInnerHTML={{
                                __html: t('home-page:appPresentationDescription'),
                            }}
                            paragraph
                        />
                    </Box>
                </StyledBoxWidth>
            }
            backgroundImage={backgroundImage}
            backgroundPosition={isBreakPoint881 ? 'top' : ' top right'}
            isOnWoopenPresentation
        />
    );
};
