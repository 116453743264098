import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';

import Box from '@mui/material/Box';

import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import { styledComponent } from 'src/utils/styled';

export const StyledBox = styledComponent(Box)<{
    $isOnWoopenPresentation?: boolean;
}>`
    background: ${colours.white.main};
    ${({ $isOnWoopenPresentation }) =>
        $isOnWoopenPresentation
            ? `
        height:24rem;
        @media (max-width: ${breakpoints.breakPoint881 - 1}px) {
        height: fit-content; padding-bottom:2rem `
            : `height:16.563rem;`};
`;

export const StyledBoxBackground = styledComponent(Box)<{
    $backgroundImage: string;
    $backgroundPosition: string;
    $isOnWoopenPresentation?: boolean;
}>`
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;

    ${({ $backgroundImage }) =>
        $backgroundImage &&
        `
        background-image: url(${$backgroundImage});
        background-color: ${colours.primaryOpacity.O04};
    `};
    ${({ $backgroundPosition }) =>
        $backgroundPosition &&
        `
        background-position: ${$backgroundPosition};
        `};
    ${({ $isOnWoopenPresentation }) =>
        $isOnWoopenPresentation ? `height:24rem; background-size:cover;` : `height:16.563rem;`};
    ${({ $isOnWoopenPresentation }) =>
        $isOnWoopenPresentation &&
        `
        width:40rem;
        @media (max-width: ${breakpoints.breakPoint881 - 1}px) {
        background-size:center;
        width: auto;
        }
        @media (max-width: ${breakpoints.breakPoint500 - 1}px) {
            background-position: left;
        }
        `};
    @media (max-width: ${breakpoints.breakPoint450 - 1}px) {
        background-size: cover;
    }

    @media (max-width: ${breakpoints.breakPoint2000 - 1}px) {
        display: flex;
        justify-content: flex-end;
    }
`;

const StyledBoxMargin = styledComponent(Box)`
    margin-left: 20rem;
    @media (max-width: ${breakpoints.breakPoint2000 - 1}px) {
        margin-left: 0rem;
    }
`;

type Props = {
    content: React.ReactNode;
    backgroundImage: string;
    backgroundPosition: string;
    isOnWoopenPresentation?: boolean;
};

const TextPresentation: React.FC<Props> = ({
    content,
    backgroundImage,
    backgroundPosition,
    isOnWoopenPresentation,
}) => {
    const { width } = useWindowSize();

    const { isTablet, isBreakPoint881 } = useMemo(
        () => ({
            isTablet: width < breakpoints.breakPoint1230,
            isBreakPoint881: width < breakpoints.breakPoint881,
        }),
        [width],
    );

    return (
        <>
            {isBreakPoint881 && isOnWoopenPresentation && (
                <StyledBoxBackground
                    $isOnWoopenPresentation={isOnWoopenPresentation}
                    data-target-id="background-image-isOnWoopenPresentation"
                    $backgroundPosition={backgroundPosition}
                    $backgroundImage={backgroundImage}
                />
            )}
            <StyledBox $isOnWoopenPresentation={isOnWoopenPresentation}>
                {!isBreakPoint881 && isOnWoopenPresentation && (
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box data-target-id="content">{content}</Box>
                        <StyledBoxBackground
                            $isOnWoopenPresentation={isOnWoopenPresentation}
                            data-target-id="background-image-isOnWoopenPresentation"
                            $backgroundPosition={backgroundPosition}
                            $backgroundImage={backgroundImage}
                        />
                    </Box>
                )}
                {!isOnWoopenPresentation && (
                    <StyledBoxBackground
                        $isOnWoopenPresentation={isOnWoopenPresentation}
                        data-target-id="background-image"
                        $backgroundPosition={backgroundPosition}
                        $backgroundImage={backgroundImage}
                    >
                        {!isTablet && <StyledBoxMargin data-target-id="content">{content}</StyledBoxMargin>}
                    </StyledBoxBackground>
                )}
                {isBreakPoint881 && isOnWoopenPresentation && content}
            </StyledBox>
            {isTablet && !isOnWoopenPresentation && content}
        </>
    );
};

export default TextPresentation;
