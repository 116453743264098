import React from 'react';
import dynamic from 'next/dynamic';

import { useTranslation } from 'next-i18next';

import { useMobileStoreLinksQuery } from 'src/hooks/__generated__/queries';
import { AnalyticsEvent, EventParamKeys, ItemNames } from 'src/utils/analytics';
import useDynamicLogEvents from 'src/hooks/useDynamicLogEvents';

const AppleStoreFr = dynamic(() => import('src/assets/logos/app-store-fr.svg'), { ssr: false });
const AppleStoreEn = dynamic(() => import('src/assets/logos/app-store-en.svg'), { ssr: false });

const GooglePlayFr = dynamic(() => import('src/assets/logos/google-play-fr.svg'), { ssr: false });
const GooglePlayEn = dynamic(() => import('src/assets/logos/google-play-en.svg'), { ssr: false });

interface Props {
    store: 'google' | 'apple';
    width: number;
    height: number;
    dataTargetId?: string;
}

const StoreButton: React.FC<Props> = ({ store, width, height, dataTargetId }) => {
    const { data } = useMobileStoreLinksQuery();
    const { i18n } = useTranslation(['common']);
    const { logEventDynamic } = useDynamicLogEvents();

    let storeImage;
    if (store === 'google') {
        if (i18n.language === 'fr') {
            storeImage = (
                <GooglePlayFr width={width} height={height} data-target-id={dataTargetId ?? 'google-play-button'} />
            );
        }
        if (i18n.language === 'en') {
            storeImage = (
                <GooglePlayEn width={width} height={height} data-target-id={dataTargetId ?? 'google-play-button'} />
            );
        }
    }
    if (store === 'apple') {
        if (i18n.language === 'fr') {
            storeImage = (
                <AppleStoreFr width={width} height={height} data-target-id={dataTargetId ?? 'apple-store-button'} />
            );
        }
        if (i18n.language === 'en') {
            storeImage = (
                <AppleStoreEn width={width} height={height} data-target-id={dataTargetId ?? 'apple-store-button'} />
            );
        }
    }

    const isGoogle = store === 'google';
    const link = isGoogle ? data?.googlePlay : data?.appleStore;
    return (
        <a
            href={String(link)}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
                logEventDynamic(isGoogle ? AnalyticsEvent.GoogleStore : AnalyticsEvent.AppleStore, {
                    [EventParamKeys.ItemName]: isGoogle ? ItemNames.Google : ItemNames.Apple,
                });
            }}
        >
            {storeImage}
        </a>
    );
};

export default StoreButton;
