import React, { useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';

import Box from '@mui/material/Box';

import type { CustomQueryVariablesCommunitySearch } from 'src/components/community/CommunitySearchForm';
import CommunitySearchForm from 'src/components/community/CommunitySearchForm';
import { Tab, Tabs } from 'src/components/home/HomeTabs';
import Button from 'src/components/ui/Button';
import Divider from 'src/components/ui/Divider';
import GooglePlacesAutocomplete from 'src/components/ui/GooglePlacesAutocomplete';
import Icon from 'src/components/ui/Icon';
import RenderLineBreaks from 'src/components/ui/RenderLineBreaks';
import Typography from 'src/components/ui/Typography';

import { useManagedRouter } from 'src/hooks/useAppRouter';
import useAuthenticatedRedirect from 'src/hooks/useAuthenticatedRedirect';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useCommunityFilters from 'src/hooks/useCommunityFilters';
import useDynamicLogEvents from 'src/hooks/useDynamicLogEvents';

import { breakpoints } from 'src/constants/breakpoints';
import { colours } from 'src/constants/colours';
import { routePathAd, routePathCreateAdStep1, routePathEstimation } from 'src/constants/router';

import { AnalyticsEvent } from 'src/utils/analytics';
import { styledComponent } from 'src/utils/styled';

const Container = styledComponent(Box)<{
    $backgroundUrl?: string;
}>`
    background-image: ${({ $backgroundUrl }) => $backgroundUrl && `url(${$backgroundUrl})`};
    background-size: 145rem;
    background-position-x: left;
    background-position-y: 60%;
    height: 37.5rem;
    @media (min-width: ${breakpoints.desktopExtraLarge}px) {
        background-size: cover;
    }
    @media (max-width: ${breakpoints.desktopLarge - 1}px) {
        height: 30rem;
        background-size: 100rem;
        background-position-y: 70%;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        background-position-x: 25%;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        height: 28.5rem;
        background-position-x: 45%;
        background-position-y: 85%;
    }
`;

const Content = styledComponent(Box)`
    margin-left: 5.5rem;
    margin-top: 8.75rem;
    width: 48.25rem;
    @media (max-width: ${breakpoints.desktopLarge - 1}px) {
        margin: auto auto 3.25rem auto;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        width: 43rem;
        margin: auto auto 3rem auto;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        width: 20.5rem;
        margin: auto auto 1.5rem auto;
    }
`;

const Title = styledComponent(Typography)`
    font-size: 2.5rem;
    color: ${colours.white.main};
    font-weight: 500;
    line-height: 1.5;
    @media (max-width: ${breakpoints.desktopLarge - 1}px) {
        text-align: center;
        font-size: 2rem;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        font-size: 1.5rem;
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        width: 30rem;
        margin: auto;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 1.25rem;
        width: 20rem;
    }
`;

const TabsContainer = styledComponent(Box)`
    background-color: ${colours.white.main};
    padding: 0 2rem 0.5rem 2rem;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        padding: 0 1rem 0.5rem 1rem;
    }
`;

const DividerWrapper = styledComponent(Box)`
    margin-top: 1.875rem;
    margin-bottom: 0.5rem;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        margin-top: 1rem;
    }
`;

const StyledIcon = styledComponent(Icon)`
    margin-bottom: 1.5rem;
`;

// Need to make a custon Button to add the "component" prop
const LetsGoButton = styledComponent(Button)<{ component?: string }>``;

type TabTypes = 'search-ad' | 'find-pros' | 'estimate-ad' | 'create-ad';

const HomeSearch: React.FC = () => {
    const { t } = useTranslation(['home-page', 'community']);
    const { router } = useManagedRouter();
    const authenticatedRedirect = useAuthenticatedRedirect();
    const { compileParamsAndRedirectToCommunityStep2 } = useCommunityFilters();
    const { logEventDynamic } = useDynamicLogEvents();

    const [selectedTab, setSelectedTab] = useState<TabTypes>('search-ad');

    const [adSearchLocation, setAdSearchLocation] = useState<string>();

    const { isMobile } = useBreakpoints();

    const handleTabChange = async (_ev: React.SyntheticEvent, newTab: TabTypes) => {
        setSelectedTab(newTab);

        if (newTab === 'estimate-ad') {
            logEventDynamic(AnalyticsEvent.EstimateAd);
            await router.push(routePathEstimation);
        } else if (newTab === 'create-ad') {
            logEventDynamic(AnalyticsEvent.CreateAd);
            await authenticatedRedirect(routePathCreateAdStep1);
        }
    };

    const handleForm = async (values: CustomQueryVariablesCommunitySearch) => {
        logEventDynamic(AnalyticsEvent.SearchPros);
        await compileParamsAndRedirectToCommunityStep2(values);
    };

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    // @ts-expect-error -- React doesn't recognize fetchpriority but it exists
                    fetchpriority="high"
                    as="image"
                    href={`${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/search-bg.webp`}
                    type="image/webp"
                />
            </Head>
            <Container
                display="flex"
                $backgroundUrl={`${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/search-bg.webp`}
            >
                <Content>
                    <RenderLineBreaks>
                        <Title variant="h1" data-target-id="HomeSearch-title" serif>
                            {t(`home-page:${isMobile ? 'searchBoxTitleMobile' : 'searchBoxTitle'}`)}
                        </Title>
                    </RenderLineBreaks>
                    <TabsContainer
                        display="flex"
                        flexDirection="column"
                        color={colours.white.main}
                        borderRadius="0.5rem"
                        mt="1.5rem"
                    >
                        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" centered>
                            <Tab
                                label={t('home-page:searchAd')}
                                value="search-ad"
                                icon={<StyledIcon name="SearchRealEstate" size="2rem" />}
                                data-target-id="search-ad-tab"
                            />
                            <Tab
                                label={t('home-page:findPros')}
                                value="find-pros"
                                icon={<StyledIcon name="Network" size="2rem" />}
                                data-target-id="find-pros-tab"
                            />
                            <Tab
                                label={t('home-page:estimateProperty')}
                                value="estimate-ad"
                                icon={<StyledIcon name="Financial" size="2rem" />}
                                data-target-id="estimate-ad-tab"
                            />
                            <Tab
                                label={t('home-page:createAd')}
                                value="create-ad"
                                icon={<StyledIcon name="RealEstateBuilding" size="2rem" />}
                                data-target-id="create-ad-tab"
                            />
                        </Tabs>
                        <DividerWrapper>
                            <Divider />
                        </DividerWrapper>
                        <>
                            {selectedTab === 'search-ad' && (
                                <Box width="100%" display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                                    <Box display="block" flex="1">
                                        <Formik initialValues={{ place: undefined }} onSubmit={console.info}>
                                            <GooglePlacesAutocomplete
                                                name="place"
                                                label={t('common:location')}
                                                onChangeOption={(option) => setAdSearchLocation(option?.description)}
                                                heightVariant="small"
                                                includeGeometry
                                                onlyFrance
                                            />
                                        </Formik>
                                    </Box>
                                    <Box margin={isMobile ? '0' : 'auto auto auto 1rem'}>
                                        <LetsGoButton
                                            component="div"
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            data-target-id="home-search-lets-go-button"
                                            fullWidth={isMobile}
                                            onClick={async (event) => {
                                                event.stopPropagation();
                                                logEventDynamic(AnalyticsEvent.SearchAds);
                                                await router.push({
                                                    pathname: routePathAd,
                                                    query: adSearchLocation
                                                        ? {
                                                              address: adSearchLocation,
                                                          }
                                                        : undefined,
                                                });
                                            }}
                                            style={{ height: '2.5rem' }}
                                        >
                                            {t('home-page:letsGo')}
                                        </LetsGoButton>
                                    </Box>
                                </Box>
                            )}
                            {selectedTab === 'find-pros' && (
                                <Box marginY="0.9rem" sx={{ transform: 'scale(1, 0.96)' }}>
                                    <CommunitySearchForm
                                        label={t('community:searchInCommunity')}
                                        onSubmit={handleForm}
                                        showSeeMoreButton
                                    />
                                </Box>
                            )}
                        </>
                    </TabsContainer>
                </Content>
            </Container>
        </>
    );
};

export default HomeSearch;
