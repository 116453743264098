import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useWindowSize } from 'react-use';

import Box from '@mui/material/Box';
import type { SxProps } from '@mui/material/styles';

import TextPresentation from './TextPresentation';
import { breakpoints } from 'src/constants/breakpoints';
import { styledComponent } from 'src/utils/styled';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import { colours } from 'src/constants/colours';
import Icon from '../ui/Icon';
import useAuthenticatedRedirect from 'src/hooks/useAuthenticatedRedirect';
import { routePathCreateAdStep1WithTypeRent, routePathCreateAdStep1WithTypeSell } from 'src/constants/router';

const StyledSectionBox = styledComponent(Box)`
    max-width: 31rem;
    align-items: center;
    @media (max-width: ${breakpoints.breakPoint2000 - 1}px) {
        align-items: flex-start;
    }
    @media (max-width: ${breakpoints.breakPoint1230 - 1}px) {
        align-items: center;
    }
`;

const StyledSectionTitle = styledComponent(Typography)`
    font-size: 2rem;
    text-align: center;
    @media (max-width: ${breakpoints.breakPoint2000 - 1}px) {
        text-align: flex-start;
    }
    @media (max-width: ${breakpoints.breakPoint1230 - 1}px) {
        text-align: center;
    }
`;

const StyledSectionText = styledComponent(Typography)`
    max-width: 31rem;
    text-align: center;
    @media (max-width: ${breakpoints.breakPoint2000 - 1}px) {
        text-align: left;
    }
    @media (max-width: ${breakpoints.breakPoint1230 - 1}px) {
        text-align: center;
    }
`;

const StyledBoxButtonDirection = styledComponent(Box)`
    display: flex;
    flex-direction: row;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        flex-direction: column;
    }
`;

const StyledBoxButton = styledComponent(Box)`
    margin-left: 1rem;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        margin-left: 0rem;
    }
`;

const styledButton: SxProps = {
    width: '14.375rem',
    [`@media screen and (max-width: ${breakpoints.tabletPortrait - 1}px)`]: {
        width: '20.375rem',
        marginBottom: '0.5rem',
    },
    height: '3rem',
    backgroundColor: colours.tertiary.main,
};

export const IndividualPresentation: React.FC = () => {
    const { t, i18n } = useTranslation(['home-page']);
    const { width } = useWindowSize();
    const authenticatedRedirect = useAuthenticatedRedirect();

    const { isTablet, isBreakPoint450 } = useMemo(
        () => ({
            isTablet: width < breakpoints.breakPoint1230,
            isBreakPoint450: width < breakpoints.breakPoint450,
        }),
        [width],
    );

    const backgroundImage = isBreakPoint450
        ? `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/home-section-tablet-portrait.webp`
        : `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/home-section.webp`;

    return (
        <TextPresentation
            content={
                <Box
                    display="flex"
                    flexDirection="row"
                    ml="2rem"
                    textAlign={isTablet ? 'center' : undefined}
                    justifyContent={isTablet ? 'center' : 'flex-end'}
                    mb={isTablet ? '2rem' : undefined}
                    mr={isTablet ? '2rem' : undefined}
                >
                    <Box display="flex" flexDirection="column" mr={isTablet ? undefined : '5.5rem'}>
                        <StyledSectionBox display="flex" flexDirection="column" pt="2rem">
                            <StyledSectionTitle data-target-id="title" variant="h2">
                                {t('home-page:individualPresentationTitle')}
                            </StyledSectionTitle>
                            <StyledSectionText
                                mt="1rem"
                                dangerouslySetInnerHTML={{
                                    __html: t('home-page:individualPresentationText'),
                                }}
                                paragraph
                            />
                        </StyledSectionBox>
                        <StyledBoxButtonDirection display="flex" flexDirection="row">
                            <Box>
                                <Button
                                    sx={styledButton}
                                    data-target-id="button1"
                                    startIcon={
                                        <Icon name={i18n.language === 'en' ? 'ForSaleEn' : 'Sell'} color="white" />
                                    }
                                    onClick={() => authenticatedRedirect(routePathCreateAdStep1WithTypeSell)}
                                >
                                    {t('home-page:iHaveHousingToSell')}
                                </Button>
                            </Box>
                            <StyledBoxButton>
                                <Button
                                    sx={styledButton}
                                    data-target-id="button2"
                                    startIcon={<Icon name="Rent" />}
                                    onClick={() => authenticatedRedirect(routePathCreateAdStep1WithTypeRent)}
                                >
                                    {t('home-page:iHaveHousingToRent')}
                                </Button>
                            </StyledBoxButton>
                        </StyledBoxButtonDirection>
                    </Box>
                </Box>
            }
            backgroundImage={backgroundImage}
            backgroundPosition="left"
        />
    );
};
