import type { PropsWithChildren } from 'react';
import React, { useState, useRef, useEffect } from 'react';

import { useWindowSize } from 'react-use';
import type { ApolloQueryResult } from '@apollo/client';

import IconButton from '@mui/material/IconButton';
import type { CardActionsProps } from '@mui/material/CardActions';
import CardActions from '@mui/material/CardActions';

import Box from '@mui/material/Box';

import Icon from 'src/components/ui/Icon';

import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import theme from 'src/utils/theme';

import type { UserAdsQuery, PostsQuery, ProjectsQuery, MembersAdsQuery } from 'src/types/__generated__/graphql';
import Loader from 'src/components/ui/Loader';
import { styledComponent, styledTag } from 'src/utils/styled';

const Scroller = styledTag('div')<{
    $width: string;
    $height: string;
}>`
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
`;

const ScrollContainer = styledTag('div')`
    overflow-x: scroll; /* Allow horizontal scrolling */
    scroll-snap-type: x mandatory; /* Snap scrolling horizontally to available "snap points"  */

    /* Hide horizontal scrollbar */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
`;

const StyledBox = styledComponent(Box)<{
    $isOnProjectSection?: boolean;
}>`
    ${({ $isOnProjectSection }) => $isOnProjectSection && 'margin-top: 3rem;'};
`;

interface ScrollNavProps extends CardActionsProps {
    as?: string;
}
const ScrollNav = styledComponent(CardActions)<ScrollNavProps>`
    position: absolute;
    right: 0;
    bottom: 100%;
    padding: 1rem 0;
    /* Add space between adjacent buttons */
    > :not(:first-of-type) {
        margin-left: 1rem;
    }
`;

const ScrollNavButton = styledComponent(IconButton)`
    background-color: ${colours.grey.G50};
    border: 0.0625rem solid ${theme.palette.primary.main};
    &:disabled {
        opacity: 0.25;
    }
`;

const ScrollNavButtonLeft = styledComponent(IconButton)`
    background-color: ${colours.primaryOpacity.O60};
    position: absolute;
    z-index: 1;
    right: 1%;
    bottom: 40%;
    &:disabled {
        opacity: 0.25;
    }
`;

const ScrollNavButtonRight = styledComponent(IconButton)`
    background-color: ${colours.primaryOpacity.O60};
    position: absolute;
    color: ${colours.white.main};
    z-index: 1;
    left: 1%;
    bottom: 40%;
    &:disabled {
        opacity: 0.25;
    }
`;

const defaultMarginLeftBetweenCardInPx = 24;

export const SnapItem = styledComponent(Box)<{
    $marginLeftBetweenCardInPx?: number;
}>`
    scroll-snap-align: start; /* Use the start of this box as "Snap point" */

    /* Add space between adjacent cards */
    &:not(:first-of-type) {
        margin-left: ${({ $marginLeftBetweenCardInPx }) =>
            $marginLeftBetweenCardInPx ?? defaultMarginLeftBetweenCardInPx}px;
        }
    }
`;

type ApolloQueries = UserAdsQuery | PostsQuery | ProjectsQuery | MembersAdsQuery;
export interface Props<T extends ApolloQueries> {
    actualCardsLength?: number;
    totalCardsLength?: number;
    loading?: boolean;
    isOnProjectSection?: boolean;
    isOnHomePage?: boolean;
    handleFetchMore?: () => Promise<ApolloQueryResult<T | undefined>>;
    cardWidthInPx?: number;
    cardHeightInRem?: string;
    marginLeftBetweenCardInPx?: number;
    paddingLeftInPx?: number;
    paddingRightInPx?: number;
    marginBottom?: string | number;
}

const HorizontalScroller = <T extends ApolloQueries>({
    actualCardsLength,
    totalCardsLength,
    loading,
    isOnHomePage,
    isOnProjectSection,
    children,
    handleFetchMore,
    cardWidthInPx,
    cardHeightInRem,
    marginLeftBetweenCardInPx = defaultMarginLeftBetweenCardInPx,
    paddingLeftInPx = 0,
    paddingRightInPx = 0,
    marginBottom,
}: PropsWithChildren<Props<T>>) => {
    const [cardOffset, setCardOffset] = useState(0);
    const scrollerContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollerRef = useRef<HTMLDivElement | null>(null);
    const [isInitScroll, setIsInitScroll] = useState(true); // used to keep div on first story on mount
    const [isTheEnd, setIsTheEnd] = useState(false);
    const [isTheStart, setIsTheStart] = useState(true);
    const [hideArrows, setHideArrows] = useState(true);
    const { width } = useWindowSize();

    useEffect(() => {
        if (
            !isInitScroll &&
            actualCardsLength &&
            scrollerContainerRef.current &&
            scrollerRef.current &&
            cardWidthInPx
        ) {
            scrollerContainerRef.current.scrollTo({
                left: (cardWidthInPx + marginLeftBetweenCardInPx) * cardOffset - marginLeftBetweenCardInPx,
            });
        }
        const scrollerWidth = scrollerRef.current?.clientWidth ?? 0;
        const scrollerContainerWidth = scrollerContainerRef.current?.clientWidth ?? 0;
        setHideArrows(Boolean(scrollerWidth <= scrollerContainerWidth));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualCardsLength]);

    const handleScroll = async () => {
        const isTheEndTemp =
            Math.round(scrollerContainerRef.current?.scrollLeft ?? 0) >=
            Math.round(
                (scrollerContainerRef.current?.scrollWidth ?? 0) - (scrollerContainerRef.current?.clientWidth ?? 0),
            );
        if (isTheEndTemp || isTheEnd) {
            setIsTheEnd(isTheEndTemp);
        }
        const isTheStartTemp = Math.round(scrollerContainerRef.current?.scrollLeft ?? 0) === 0;
        if (isTheStartTemp || isTheStart) {
            setIsTheStart(isTheStartTemp);
        }
        if (scrollerContainerRef.current && cardWidthInPx) {
            const nextOffset = Math.floor(
                scrollerContainerRef.current.scrollLeft / (cardWidthInPx + marginLeftBetweenCardInPx),
            );
            if (isInitScroll) {
                scrollerContainerRef.current.scrollTo({
                    left: 0,
                });
            } else if (cardOffset !== nextOffset) {
                setCardOffset(nextOffset);
            }
            setIsInitScroll(false);
        }
        if (scrollerRef.current && scrollerContainerRef.current && cardWidthInPx) {
            const { right: childRight } = scrollerRef.current.getBoundingClientRect();
            const { right: parentRight } = scrollerContainerRef.current.getBoundingClientRect();
            const isScrollRight = childRight - parentRight < cardWidthInPx;
            if (
                isScrollRight &&
                !loading &&
                actualCardsLength &&
                totalCardsLength &&
                actualCardsLength < totalCardsLength &&
                handleFetchMore
            ) {
                await handleFetchMore();
            }
        }
    };

    const handleNavigatePrev = () => {
        if (scrollerContainerRef.current && scrollerRef.current && actualCardsLength) {
            const cardWidth = Math.floor(scrollerRef.current.clientWidth / actualCardsLength);
            scrollerContainerRef.current.scrollTo({
                behavior: 'smooth',
                left: scrollerContainerRef.current.scrollLeft - cardWidth,
            });
            setIsInitScroll(false);
        }
    };

    const handleNavigateNext = () => {
        if (scrollerContainerRef.current && scrollerRef.current && actualCardsLength) {
            const cardWidth = Math.floor(scrollerRef.current.clientWidth / actualCardsLength);
            scrollerContainerRef.current.scrollTo({
                behavior: 'smooth',
                left: scrollerContainerRef.current.scrollLeft + cardWidth,
            });
            setIsInitScroll(false);
        }
    };
    let scrollerWidthInPx = 0;
    if (cardWidthInPx && actualCardsLength && totalCardsLength) {
        scrollerWidthInPx =
            paddingLeftInPx +
            (cardWidthInPx + marginLeftBetweenCardInPx) * actualCardsLength -
            marginLeftBetweenCardInPx +
            paddingRightInPx;
    }

    const showScrollNav = width >= breakpoints.tabletPortrait && !hideArrows && totalCardsLength;
    return (
        <StyledBox $isOnProjectSection={isOnProjectSection} mb={marginBottom} position="relative">
            {showScrollNav && !isOnHomePage && (
                <ScrollNav as="section">
                    <ScrollNavButton onClick={handleNavigatePrev} disabled={isTheStart}>
                        <Icon name="ChevronLeft" size="1rem" />
                    </ScrollNavButton>
                    <ScrollNavButton onClick={handleNavigateNext} disabled={isTheEnd}>
                        <Icon name="ChevronRight" size="1rem" />
                    </ScrollNavButton>
                </ScrollNav>
            )}
            {totalCardsLength && totalCardsLength > 0 ? (
                <ScrollContainer ref={scrollerContainerRef} onScroll={handleScroll}>
                    {showScrollNav && isOnHomePage && (
                        <>
                            <ScrollNavButtonRight onClick={handleNavigatePrev} disabled={isTheStart}>
                                <Icon name="ChevronLeft" size="0.70rem" color="white" />
                            </ScrollNavButtonRight>

                            <ScrollNavButtonLeft onClick={handleNavigateNext} disabled={isTheEnd}>
                                <Icon name="ChevronRight" size="0.70rem" color="white" />
                            </ScrollNavButtonLeft>
                        </>
                    )}
                    <Scroller
                        key={actualCardsLength}
                        ref={scrollerRef}
                        $width={cardWidthInPx && actualCardsLength ? `${scrollerWidthInPx}px` : 'fit-content'}
                        $height={cardHeightInRem as string}
                    >
                        {children}
                        {loading && (!actualCardsLength || actualCardsLength === 0) && <Loader />}
                    </Scroller>
                </ScrollContainer>
            ) : null}
        </StyledBox>
    );
};

export default HorizontalScroller;
