import React from 'react';
import { useTranslation } from 'next-i18next';

import Box from '@mui/material/Box';

import Image from 'src/components/ui/Image';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import { routePathEstimation } from 'src/constants/router';
import { styledComponent } from 'src/utils/styled';
import { breakpoints } from 'src/constants/breakpoints';

const StyledImage = styledComponent(Image)`
    object-fit: cover;
    object-position: 50% 63%;
    width: 100%;
`;

const TitleTypography = styledComponent(Typography)`
    font-size: 2rem;
    font-weight: bold;
    margin-right: 1rem;
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        font-size: 1.5rem;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 1.25rem;
    }
`;

const Content = styledComponent(Box)`
    margin-left: 5rem;
    margin-top: 1.3rem;
    @media (max-width: ${breakpoints.breakPoint881 - 1}px) {
        margin-left: 2rem;
    }
`;

const StyledBox = styledComponent(Box)`
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    @media (max-width: ${breakpoints.tabletPortrait}px) {
        margin-right: 2rem;
        flex-direction: column;
    }
`;

const EstimateButtonContainer = styledComponent(Box)`
    flex: 1;
    display: flex;
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;

    @media (max-width: ${breakpoints.tabletPortrait}px) {
        padding-top: 1.5rem;
        padding-left: 0;
        padding-right: 0;
        justify-content: start;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        justify-content: center;
    }
`;

export const EstimationPresentation: React.FC = () => {
    const { t } = useTranslation(['home-page']);

    return (
        <Box>
            <StyledImage
                height={225}
                src={`${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/home/estimation.webp`}
                alt="Estimation image"
            />
            <Content>
                <TitleTypography variant="h2" mb="1rem">
                    {t('home-page:estimationPresentationTitle')}
                </TitleTypography>
                <StyledBox>
                    <Box display="flex" flexDirection="column">
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: t('home-page:estimationPresentationText'),
                            }}
                        />
                        <Typography mt="1rem" weight="bold">
                            {t('home-page:estimationPresentationTagline')}
                        </Typography>
                    </Box>
                    <EstimateButtonContainer>
                        <Button sx={{ width: 'max-content', height: 'fit-content' }} href={routePathEstimation}>
                            {t('home-page:estimatePropertyButton')}
                        </Button>
                    </EstimateButtonContainer>
                </StyledBox>
            </Content>
        </Box>
    );
};
