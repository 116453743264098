import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

import theme from 'src/utils/theme';
import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import { styledComponent } from 'src/utils/styled';

export const Tabs = styledComponent(MuiTabs)`
    position: relative;
    .MuiTabs-indicator {
        background-color: ${theme.palette.secondary.main};
        height: 0.5rem;
        top: 0;
        border-radius: 0 0 0.5rem 0.5rem;
    }
`;

export const Tab = styledComponent(MuiTab)`
    text-transform: none;
    font-size: 1.125rem;
    color: ${colours.grey.G500};
    min-width: 0;
    white-space: nowrap;
    margin: 0.4rem;
    padding-top: 1.5rem;
    &.Mui-selected {
        color: ${theme.palette.secondary.main};
        font-weight: bold;
        border-radius: 0 0 0.5rem 0.5rem;
    }
    &.Mui-selected::before {
        position: absolute;
        content: '';
        left: 47%;
        top: 30%;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: ${colours.secondary.O16};
    }
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        font-size: 1rem;
    }
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        font-size: 0.75rem;
        white-space: normal;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
`;
