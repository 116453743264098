import useAppRouter from 'src/hooks/useAppRouter';
import useLoginModal from 'src/hooks/useLoginModal';

/**
 * This hook allow to redirect authenticated user to the page provided in authenticatedRedirect(path)
 * if the user is not authenticated, it opens the login modal and, at the end of the signIn signUp flow, it redirects the user to the page provided in authenticatedRedirect(path)
 * @example
 * ```ts
 * import useAuthenticatedRedirect from 'src/hooks/useAuthenticatedRedirect';
 * // ...
 * const authenticatedRedirect = useAuthenticatedRedirect();
 * // ...
 * onClick={() => authenticatedRedirect(routePathProfileEstimateStep1)}
 * ```
 */
const useAuthenticatedRedirect = () => {
    const router = useAppRouter();
    const { createAuthenticatedHandler } = useLoginModal();
    const authenticatedRedirect = async (pathToRedirect: string) => {
        const redirect = async () => {
            await router.push(pathToRedirect);
        };
        await createAuthenticatedHandler(redirect, pathToRedirect);
    };

    return authenticatedRedirect;
};

export default useAuthenticatedRedirect;
